
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import PaymentMethods from '@/components/pages/shared/payment/PaymentMethods.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import { notify } from '@kyvg/vue3-notification'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { TmButton, TmModal, PaymentMethods, TmTooltip },
  setup() {
    const router = useRouter()
    const handleClick = () => {
      notify({
        text: 'Your BYOC 10K free trial plan has been successfully activated.',
        type: 'success',
      })
      router.push({ name: 'base.chats.settings.channels.flow' })
    }

    return {
      handleClick,
    }
  },
})
